import React from 'react';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';

import Burger from '../../../assets/img/icons/ic-menu.svg';
import NavigationItems from '../NavigationItems/NavigationItems';
import SignNav from '../SignNav/SignNav';
import { UNAUTH_NAV_DATA, PARTNER_NAV_DATA, SEARCH_LINK } from '../../../constans/links';
import classes from './UnAuth.module.scss';
import http from '../../../services/http.service';
import { API } from '../../../constans/http';
import StorageService from '../../../services/storage.service';
import { objectIsNotEmpty } from '../../../helpers/helper-methods';
import IconSearch from '../../../assets/img/icons/ic-search.svg';
import { COOKIES_KEYS, LOCALSTORAGE_KEYS } from '../../../constans/localstorage';
import SessionService from '../../../services/session.service';
import CookiesService from '../../../services/cookie.service';

const getUser = () => StorageService().get(LOCALSTORAGE_KEYS.USER);

function UnAuth({
  toggleSideDrawer, hideLinks, userName, isCustomer, isNotSeller, isPartner = false,
}) {
  const handleSignout = () => {
    // If no data redirect to home page.
    if (objectIsNotEmpty(getUser())) {
      http.delete(API.USER_LOGIN)
        //  In storage we could have async callback function as subscribers.
        // eslint-disable-next-line max-len
        // To prevent update state of an unmounted component we add one more then block to wait for updates
        .then(() => StorageService().clear())
        .then(() => SessionService().clear())
        .then(() => CookiesService().eraseCookie(COOKIES_KEYS.PARTNER_NICKNAME))
        .then(() => navigate(SEARCH_LINK));
    } else {
    //  navigate(SIGN_IN);
    }
  };

  return (
    <>
      <nav className={`${classes.nav} ${classes.desktopOnly}`}>
        {!hideLinks && (
          <NavigationItems
            isNotSeller={isNotSeller}
            linksArr={isPartner ? PARTNER_NAV_DATA : UNAUTH_NAV_DATA}
            containerClasses="flex flex-wrap flex-v-center"
          />
        )}
      </nav>
      <div className={`${classes.menu} ${classes.mobileOnly}`}>
        <Burger onClick={toggleSideDrawer} />
      </div>
      {!userName
        ? <SignNav containerClasses={classes.signBlock} />
        : (
          <>
            {isCustomer && (
              <button
                type="button"
                onClick={() => navigate('/search')}
                className={`${classes.search} btn `}
              >
                <IconSearch />
              </button>
            )}
            <div onClick={handleSignout} className={classes.username}>
              {/* should be > 1 as userName = `${firstName} ${lastName}` */}
              {userName.length > 1 ? userName : 'Log Out'}
              <div className={`${classes.logOutTooltip} t-500`}>
                Click to Logout
              </div>
            </div>
          </>
        )}
    </>
  );
}

UnAuth.propTypes = {
  toggleSideDrawer: PropTypes.func,
  hideLinks: PropTypes.bool,
  userName: PropTypes.string,
};

UnAuth.defaultProps = {
  toggleSideDrawer: () => { },
  hideLinks: false,
  userName: '',
};

export default UnAuth;
